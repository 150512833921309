<sl-dropdown>
    <sl-button data-testid="topbar-user-button-component-name-button" caret size="large" slot="trigger" variant="primary">{{ userName }} ({{ workspace | capitalizeFirst }})</sl-button>

    <sl-menu>
        <sl-menu-item [routerLink]="buildUserPath()">{{ "menu.profile" | translate }}</sl-menu-item>
        @if (showEnv()) {
            <sl-menu-item>
                {{ "menu.organization" | translate }}
                <sl-menu slot="submenu">
                    <sl-menu-item (click)="setEnv(1)">Finboot</sl-menu-item>
                    <sl-menu-item (click)="setEnv(0)">Repsol</sl-menu-item>
                </sl-menu>
            </sl-menu-item>
        }
        <sl-menu-item (click)="logout()">{{ "menu.logout" | translate }}</sl-menu-item>
    </sl-menu>

</sl-dropdown>
