import {ApplicationConfig, importProvidersFrom} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule} from "@angular/forms";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HttpClient, provideHttpClient, withInterceptors, withInterceptorsFromDi} from "@angular/common/http";
import {NgxSpinnerModule} from "ngx-spinner";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {authInterceptor} from "./interceptor/auth.interceptor";
import {provideRouter} from "@angular/router";
import {appRoutes} from "./app.routes";
import {ApiClient} from "./support/apiClient.service";
import {MarcoAccountsService} from "./services/marcoAccountsService.service";
import {PrincipalAuthService} from "./services/principal-auth.service";
import {BlRequestService} from "./support/blRequest.service";
import {BlUsersService} from "./services/blocklabs/blUsersService.service";
import {BlFacilitiesService} from "./services/blocklabs/blFacilitiesService.service";
import {BlCertificateRequestsService} from "./services/blocklabs/blCertificateRequestsService.service";
import {BlSampleTypesRequestsService} from "./services/blocklabs/blSampleTypesRequestsService.service";
import {BlSampleSourcesRequestsService} from "./services/blocklabs/blSampleSourcesRequestsService.service";
import {BlResultsService} from "./services/blocklabs/blResultsService.service";
import {BlQRFileService} from "./services/blocklabs/blQRFileService.service";
import {BlStagesService} from "./services/blocklabs/blStagesService.service";
import {BlShippingInformationService} from "./services/blocklabs/blShippingInformationService.service";
import {BlLabwareInformationService} from "./services/blocklabs/blLabwareInformationService.service";
import {BlFileStorageService} from "./services/blocklabs/blFileStorageService.service";
import {BlReportService} from "./services/blocklabs/blReport.service";
import {BlMetadataService} from "./services/blocklabs/blMetadataService.service";
import {RolesManager} from "./support/rolesManager";
import {ErrorsManager} from "./support/errorsManager";
import {WorkspaceService} from "./services/workspace.service";
import {createTranslateLoader} from "../main";
import {HttpCancelService} from "./services/HttpCancelService";
import AuthHelper from "./support/authHelper";
import LocalManager from "./support/localManager";
import {AlertService} from "./services/alert.service";
import {BlCompoundService} from './services/blocklabs/blCompound.service';
import {BlSampleClassificationService} from './services/blocklabs/blSampleClassification.service';
import {BlSampleMixTypeService} from './services/blocklabs/blSampleMixType.service';
import {BlSampleSDSService} from './services/blocklabs/blSamplesSDSService.service';

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(BrowserModule, FormsModule, TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
                deps: [HttpClient]
            }
        }), NgxSpinnerModule),
        importProvidersFrom(BrowserAnimationsModule),
        provideHttpClient(withInterceptors([authInterceptor])),
        provideRouter(appRoutes),
        provideHttpClient(withInterceptorsFromDi()),
        ApiClient,
        AuthHelper,
        LocalManager,
        HttpCancelService,
        MarcoAccountsService,
        PrincipalAuthService,
        AlertService,
        BlRequestService,
        BlUsersService,
        BlFacilitiesService,
        BlCompoundService,
        BlCertificateRequestsService,
        BlSampleClassificationService,
        BlSampleMixTypeService,
        BlSampleTypesRequestsService,
        BlSampleSourcesRequestsService,
        BlSampleSDSService,
        BlResultsService,
        BlQRFileService,
        BlStagesService,
        BlShippingInformationService,
        BlLabwareInformationService,
        BlFileStorageService,
        BlReportService,
        BlMetadataService,
        RolesManager,
        ErrorsManager,
        WorkspaceService,
    ]
}
